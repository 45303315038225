.home-page-container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}


.heading {
    font-size: 150px;
    color: black;
    margin-bottom: 20%;
    position: absolute;
    top: 30%;
}

.ripple-container {
    height: 100%;
    width: 100%;
}

.heading:hover {
    cursor: pointer;
}

.center-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.title-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.email-container {
    gap: 3px;
    /* border: #D9D9D9 solid 2px; */
    width: 80%;
}

.email-input {
    border-color: #D9D9D9;
    border-width: 1px;
    border-radius: 4px;
    padding: 4px 6px;
    width: 100%;
}

.submit-button {
    color: white;
    background-color: black;
    padding: 4px 6px;
    border: none;
    border-radius: 3px;
}

.submit-button:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.833);
}

@media screen and (max-width:491px) {
    .heading {
        font-size: 110px;
        top: 40%;
    }
}
