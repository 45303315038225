.parent-container-splash {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    flex-direction: column;
    overflow: hidden;
}

.splash-title-container {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.splash-title-child-container {
    position: absolute;
    bottom: 50%;
}


#wrapper {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
}

.aura {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.dot-container {
    position: relative;
    width: 100%;
    height: 100%;
    animation: rotate 5s linear infinite;
    cursor: none;
}

.click-here {
    cursor: default;
    font-size: 80px;
}

.click-here-child {
    font-size: 20px;
    font-weight: bold;
}

.dot {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    position: absolute;
}

@keyframes geometric-pattern {
    0% {
        transform: translate(-50%, -50%) scale(1);
        opacity: 1;
    }

    25% {
        transform: translate(-75%, -50%) scale(1.2);
        opacity: 0.8;
    }

    50% {
        transform: translate(-50%, -75%) scale(1);
        opacity: 0.6;
    }

    75% {
        transform: translate(-25%, -50%) scale(1.2);
        opacity: 0.8;
    }

    100% {
        transform: translate(-50%, -50%) scale(1);
        opacity: 1;
    }
}

/* Positioning each dot on the circle */
/* .dot:nth-child(1) {
    top: 50%;
    left: 100%;
    transform: translate(-50%, -50%);
    background-color: red;
}

.dot:nth-child(2) {
    top: 75%;
    left: 93.3%;
    transform: translate(-50%, -50%);
    background-color: rgba(242, 189, 13, 0.903);
}

.dot:nth-child(3) {
    top: 93.3%;
    left: 75%;
    transform: translate(-50%, -50%);
    background-color: yellow;
}

.dot:nth-child(4) {
    top: 100%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: green;
}

.dot:nth-child(5) {
    top: 93.3%;
    left: 25%;
    transform: translate(-50%, -50%);
    background-color: cyan;
}

.dot:nth-child(6) {
    top: 75%;
    left: 6.7%;
    transform: translate(-50%, -50%);
    background-color: blue;
}

.dot:nth-child(7) {
    top: 50%;
    left: 0%;
    transform: translate(-50%, -50%);
    background-color: purple;
}

.dot:nth-child(8) {
    top: 25%;
    left: 6.7%;
    transform: translate(-50%, -50%);
    background-color: pink;
}

.dot:nth-child(9) {
    top: 6.7%;
    left: 25%;
    transform: translate(-50%, -50%);
    background-color: magenta;
}

.dot:nth-child(10) {
    top: 0%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: violet;
}

.dot:nth-child(11) {
    top: 6.7%;
    left: 75%;
    transform: translate(-50%, -50%);
    background-color: lightblue;
}

.dot:nth-child(12) {
    top: 25%;
    left: 93.3%;
    transform: translate(-50%, -50%);
    background-color: lightgreen;
} */

.click-to-enter {
    font-size: 16px;
    color: white;
    text-align: center;
}

.click-to-enter {
    color: white;
    font-weight: bold;
}
