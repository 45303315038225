@keyframes box-shadow {
    0% {
        box-shadow: 0 4px 15px rgba(132, 130, 130, 0.2); /* Initial state */
    }
    100% {
        box-shadow: 0 4px 15px rgba(10, 0, 0, 0.3); /* Final state */
    }
}

.chat-outer-parent {
    position: absolute;
    left: 0;
    top: 15%;
    width: 30%;
    height: 70%;
    border-radius: 15px; /* Rounded corners */
    border: 1px solid rgba(255, 255, 255, 20%); /* Semi-transparent white border */
    background: rgba(0, 0, 0, 0.041); /* Semi-transparent white background */
    backdrop-filter: blur(30px); /* Blur the background behind the element */
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
    z-index: 5;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
}

.chat-outer-parent:hover{
    animation-name: box-shadow; /* Referencing the keyframes */
    animation-duration: 1s; /* Duration of the animation */
    animation-delay: 0s; /* Start immediately */
    animation-fill-mode: forwards;

}

.message-input-parent{
    border-top: solid lightgrey;
    padding: 8px 20px;
    font-family: Inter;
    /* Body */
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    /* white */
    border-radius: 4px;
    /* border-m */
    border-width: 1px;
    /* neutral-500 */
    outline: none;
    margin-bottom: 20px;
}

.messages-parent{
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 10px;
}

